import { CommonModule } from '@angular/common'
import { Component, HostBinding, Input, OnInit, TemplateRef } from '@angular/core'

@Component({
    selector: 'pf-one-tooltip',
    templateUrl: './one-tooltip.component.html',
    styleUrls: ['./one-tooltip.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
    ]
})

export class OneTooltipComponent implements OnInit {
  @HostBinding('style.max-width.px') @Input() maxWidth: number
  text: string | TemplateRef<any>
  tpl: TemplateRef<any>
  transparent: boolean
  constructor(
  ) { }

  ngOnInit() { 
    if (typeof this.text !== 'string') {
      this.tpl = this.text as TemplateRef<any>
    }
  }

}
