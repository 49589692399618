import { Injectable } from '@angular/core'
import { ShopifyService } from './shopify.service'
import { UserService } from './user.service'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmModalComponent } from '../../shared/components/modals/confirm.modal/confirm.modal.component'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'

@Injectable({providedIn: 'root'})
export class SubscriptionEnforcementService {
  private subscription: Subscription = new Subscription()
  public markAsRead: boolean = false

  constructor(
    private shopifyService: ShopifyService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
  ) { }
  
  validateAkc() {
    const planSubscription = this.userService.userInfo?.subscription
    const factualAkc = this.shopifyService?.activeKnownCustomersCount$?.value
    const subscribedToAkc = planSubscription?.usage_plan?.max
    const isShopifyShop = this.userService.isShopifyShop
    if (
      planSubscription?.plan?.anchor_metric === 'contacts' &&
      factualAkc < 1000 &&
      factualAkc > subscribedToAkc &&
      isShopifyShop
    ) {
      this.showUpgradePlanModal(factualAkc, subscribedToAkc)
    }
  }

  showUpgradePlanModal(factualAkc: number, subscribedToAkc: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: 'Upgrade your plan',
        text: `Your current Customers count (${ factualAkc }) is greater than your plan limit (${ subscribedToAkc }). Please upgrade your plan to continue. If you have any questions, please reach out to chat support or email support@one.store`,
        acceptButton: {
          text: 'Upgrade',
          classes: 'pf-button filled green',
        },
        headerClass: 'text-center',
        disableCancel: true,
      },
    })
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate([`${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`]).finally(() => {
            this.markAsRead = true
          })
        }
      })
    )
  }
}
