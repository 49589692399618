import { BrandingService } from '../../../core/services/branding.service';
import { Injectable } from '@angular/core'
import _ from 'lodash'
import { forkJoin, Observable } from 'rxjs'
import { finalize, map } from 'rxjs/operators'
import { ApiService } from '../../../core/services/api/api.service'
import { UserService } from '../../../core/services/user.service'
import { EmailBrandingConfig } from '../../../shared/modules/email-onboarding/models/email-onboarding.model'
import { ThemeTagsResponse } from '../../../shared/modules/theme-filter/models/theme-filter.models'
import { UnlayerTemplate, UnlayerTemplateResponse } from '../models/email-editor.models'
import { templateDataCustomIcons } from '../../../../app_assets/unlayer-tools/custom-social-icons'
import { StoreState } from '../../../store/store.state'
import { Store } from '@ngrx/store'
import { HideLoading, ShowLoading } from '../../../store/loading/loading.actions'
import { DefaultLogoUrl } from '../helpers/email-editor-page.helper'

@Injectable()
export class EmailEditorApiService {
  unbrokenThemes = [
    '76019',
    '75914',
    '11420',
    'newsletter_reward_basic_alt_6',
    '75913',
    '82673',
    'newsletter_basic_alt_6',
    'thankyou_basic_alt_0',
    '75922',
    '73942',
    '75343',
    '75772',
    '10538',
    '10779',
    '10775',
    '75909',
    '75907',
    '10820',
    '75916',
    '75911',
    '10540',
    '75750',
    '76016',
    '75923',
    '75743',
    '10541',
    '10812',
    '75910',
    '10539',
    '76143',
    '75768',
    'cart_recovery_alt_26',
    'newsletter_basic_alt_7',
    'generic_autoresponder_1',
    'subscription_confirmation_2',
    'newsletter_reward_basic_alt_4',
    'newsletter_basic_alt_22',
    'newsletter_basic_alt_4',
    'newsletter_reward_basic_alt_7',
    'birthday_alt_14',
    'welcome_cus_reward_alt_13',
    'welcome_cus_alt_13',
    'welcome_reward_alt_9',
    'welcome_alt_9',
    'upsell_alt_16',
    'winback_alt_37',
    'winback_reward_basic_alt_0910',
    'upsell_reward_purple_alt_0910',
    'birthday_reward_basic_alt_0910_1',
    'upsell_reward_basic_alt_0910',
    'winback_reward_basic1_alt_0910',
    'birthday_reward_basic_alt_0910_2',
    'winback_reward_pink_alt_0910',
    'birthday_alt_9101',
    'birthday_reward_purple_alt_0910',
    'upsell_reward_blue_alt_0910',
  ]

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private store: Store<StoreState>,
    private brandingService: BrandingService
  ) {}

  public replaceTemplateData(template: any) {
    const brandingConfig: EmailBrandingConfig['email_branding_preset'] = _.get(this.userService.userInfo, 'metadata.email_branding_preset')
    const brandName = brandingConfig?.brand_name || _.get(this.userService.userInfo, 'shop.profile.name') || _.get(this.userService.userInfo, 'profile.company') || 'Your Company Name'
    const storeLink = brandingConfig?.brand_url || (this.userService.userInfo?.shop?.domain ? `https://${this.userService.userInfo?.shop?.domain}` : '')
    const customStyles: EmailBrandingConfig['email_branding_preset']['brand_custom_css'] = brandingConfig?.brand_custom_css
    const customLogo: EmailBrandingConfig['email_branding_preset']['brand_image_src'] = brandingConfig?.brand_image_src
    const socialLinks = brandingConfig?.social_links
    const _template = _.cloneDeep(template)
    // Unlayer global styles are stored separately so no need to search for them, can update them directly
    if (_template?.json?.body?.values?.linkStyle?.linkColor && customStyles) {
      // Set link color
      _template.json.body.values.linkStyle.linkColor = customStyles.linkColor
    }
    if (_template?.json?.body?.values?.textColor && customStyles) {
      // Set text color
      _template.json.body.values.textColor = customStyles.textColor
    }
    // Set branding logo
    if (_template?.json?.body) {
      this.brandingService.setEmailBrandingLogoBody(_template.json.body)
    }
    // Black magic to find and replace some json values
    JSON.stringify(_template, (_, nestedValue) => {
      if (nestedValue) {
        // Set brand name and link
        if (nestedValue?.brandName && brandName) {
          nestedValue['brandName'] = brandName
          nestedValue['link']['values']['href'] = storeLink
        }
        // set email branding logo and logo size
        if ((nestedValue?.textOrImage || nestedValue?.textOrImage === null) && customLogo) {
          nestedValue['textOrImage'] = 'logo'
          nestedValue['logoSize'] = {
            width: '20%',
            autoWidth: false,
            imgWidth: null
          }
        }

        if ((nestedValue?.logoImage || nestedValue?.logoImage === null) && customLogo) {
          nestedValue.logoImage = customLogo
        }
        // Set button colors
        if (nestedValue?.type === 'button' && customStyles) {
          const backgroundColor = nestedValue.values.buttonColors.backgroundColor
          const color = nestedValue.values.buttonColors.color
          nestedValue.values.buttonColors.backgroundColor = customStyles.buttonColor ? customStyles.buttonColor : backgroundColor
          nestedValue.values.buttonColors.color = customStyles.buttonTextColor ? customStyles.buttonTextColor : color
        }
        // Set social links
        if (nestedValue?.type === 'social') {
          // If social block is present we replace its value with email onboarding settings
          if (socialLinks) {
            nestedValue.values.icons.icons = socialLinks
          }
          // Set custom social icons
          nestedValue.values.icons.editor.data.customIcons = templateDataCustomIcons
          // don't show default icons as Unlayer's CDN is not reliable (instead all icons are set in customIcons field)
          nestedValue.values.icons.editor.data.showDefaultIcons = false
        }
      }
      return nestedValue
    })
    return _template
  }

  getTemplates(): Observable<UnlayerTemplateResponse> {
    return this.apiService.get('/v1/unlayer_email_templates')
  }

  getTemplateById(id: string): Observable<UnlayerTemplate> {
    this.store.dispatch(new ShowLoading('GetEmailTemplateById'))
    return this.apiService.get(`/v1/unlayer_email_templates/${id}`).pipe(
      map((res: UnlayerTemplate) => {
        return this.replaceTemplateData(res)
      }),
      finalize(() => {
        // Give Unlayer some time to render
        setTimeout(() => {this.store.dispatch(new HideLoading('GetEmailTemplateById'))}, 300)
      })
    )
  }

  getToolFile(name: string) {
    return this.apiService.getFile(`/app_assets/unlayer-tools/${name}`, {responseType: 'text'})
  }

  loadCustomTools(customUnlayerTools: {[key: string]: string}) {
    const requests = {}
    const keys = Object.keys(customUnlayerTools)
    keys.forEach(tool => {
      requests[tool] = this.getToolFile(tool)
    })
    return forkJoin(requests)
  }

  getTemplateTags(): Observable<ThemeTagsResponse> {
    return this.apiService.get('/v1/unlayer_email_templates/tags')
    .pipe(map(res => {
      if (res && res.categories && res.categories.length > 0) {
        return {...res, categories: res.categories.map(category => {
            if (category.tags && category.tags.length > 0) {
              return { ...category, tags: category.tags.map(tag => typeof tag === 'string' ? tag : tag.name)}
            }
            return category
          })
        }
      }
      return res
    }))
  }

  searchTemplates(filter = {}, savedId?: string): Observable<UnlayerTemplate[]> {
    let params = {}
    if (savedId) {
      params = { ...filter, picked_template_id: savedId }
    } else {
      params = filter
    }
    return this.apiService.post('/v1/unlayer_email_templates/search', params)
      .pipe(
        map(res => {
          return res.filter(template => this.unbrokenThemes.includes(template.template_id) || template.template_id === savedId)
        })
      )
  }
}
