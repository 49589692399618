import { CommonModule } from '@angular/common';
import { Component, ComponentRef, Inject, OnDestroy, Type, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModalConfig {
  title: string
  text: string
  html?: string
  preComponentText?: string
  preComponentHTML?: string
  component?: Type<any>
  componentInputs?: { [key: string]: any }
  disableCancel?: boolean
  cancelButton?: {
    text: string
    classes: string
  }
  acceptButton?: {
    text: string
    classes: string
  }
  headerClass?: string
  contentClass?: string
  footerClass?: string
}

@Component({
    selector: 'pf-confirm-modal',
    templateUrl: './confirm.modal.component.html',
    styleUrls: ['./confirm.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      MatDialogModule,
    ]
})

export class ConfirmModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: false })
  dynamicComponentContainer!: ViewContainerRef

  private componentRef: ComponentRef<any> | null = null

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalConfig,
  ) {
    // Set defaults
    if (data.acceptButton) {
      data.acceptButton.classes = data.acceptButton.classes ? data.acceptButton.classes : 'btn btn-primary'
      data.acceptButton.text = data.acceptButton.text ? data.acceptButton.text : 'OK'
    }
    if (data.cancelButton) {
      data.cancelButton.classes = data.cancelButton.classes ? data.cancelButton.classes : 'btn btn-secondary'
      data.cancelButton.text = data.cancelButton.text ? data.cancelButton.text : 'Cancel'
    }
    if (data.disableCancel) {
      this.dialogRef.disableClose = true
    }
  }

  ngAfterViewInit() {
    if (this.data.component) {
      this.loadDynamicComponent()
    }
  }

  private loadDynamicComponent() {
    if (this.data.component && this.dynamicComponentContainer) {
      this.componentRef = this.dynamicComponentContainer.createComponent(this.data.component)
      
      if (this.data.componentInputs) {
        Object.assign(this.componentRef.instance, this.data.componentInputs)
      }
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy()
    }
  }
}
